let model = {};

// dữ liệu form
model.dataForm = {
  code: undefined, // required, pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/ - Mã code của cột
  title: undefined, // required - Tiêu đề của cột
  title_lang: {    // optional k tạo input, required nếu tạo input - Đa ngôn ngữ cho tên cột
    vi: "",
    en: ""
  },
  index: undefined, // required - Thứ tự hiển thị
  // required - Định nghĩa các quyền của người dùng trên cột này
  permission: {
    view: undefined, // optional - Cho biết có quyền xem hay không
    edit: undefined, // optional - Cho biết có quyền sửa hay không
    filter: undefined, // optional - Cho biết có quyền lọc hay không
    sort: undefined, // optional - Cho biết có quyền sắp xếp hay không
  },
  // required - Định nghĩa định dạng hiển thị cho cột này
  format: {
    width: undefined, // required, max: 100, gt: 0 - Độ rộng của cột (tính theo %)
    align: undefined, // required, enum(left, right, center, justify) - Căn lề
  },
};

model.optionsAlign = [
  {
    value: "left",
    label: {
      vi: "Căn trái",
      en: "Left"
    },
  },
  {
    value: "right",
    label: {
      vi: "Căn phải",
      en: "Right"
    },
  },
  {
    value: "center",
    label: {
      vi: "Căn giữa",
      en: "Center"
    },
  },
  {
    value: "justify",
    label: {
      vi: "Căn đều 2 bên",
      en: "Justify"
    },
  },
];
model.protocol = [
  {
    value: 'selectProtocol',
    label: 'Hãy chọn Protocol'
  },
  {
    value: 'http',
    label: 'http'
  },
  {
    value: 'https',
    label: 'https'
  },
]

model.tableRules = {
  lengthMenu: [10, 20, 50, 100],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 20,
  offset: 0,
  sort: "index,title",
  defaultSort: ["index", "ascending"],
  fields: "id, code, title, index, permission, format",
  filters: "",
  dataSearch: {
    value: {
      code: "",
      title: "",
    },
    operator: {
      code: ":regex_i:",
      title: ":regex_i:",
    },
  },
};

export default model;
